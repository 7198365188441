<template>
  <div class="profile p-t-20">
    <router-view />
    <ProfileAddUser />
  </div>
</template>

<script>
import ProfileAddUser from '@/views/profile/components/profile-add-user/ProfileAddUser'
export default {
  name: 'Profile',
  components: { ProfileAddUser },
}
</script>
